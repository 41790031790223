@media screen and (max-width: 1199px) {
  .mobile-only {
    display: initial;
  }
  #slider_movie .info-container {
    height: 100%;
    background: #000;
  }
  #slider_movie {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    display: initial;
    bottom: 0;
    background: #000;
  }
  #slider_tvshow {
    background: #000;
  }
  .top-divider {
    height: 95px;
  }
  #slider_movie .backdrop {
    display: none;
  }
  #logo {
    position: relative;
  }
  #toolbar {
    display: none;
  }
  #sidetools {
    top: 90px;
    height: calc(100% - 150px);
  }
  #header .mobile-only {
    display: initial;
  }
  #header .mobile-menu {
    display: block;
  }
  .mobile-menu__item {
    display: inline-block;
    color: #fff;
    opacity: 0.75;
    font-weight: 700;
    padding: 5px 20px;
    text-transform: uppercase;
    font-size: 14px;
    border-bottom: 3px solid transparent;
  }
  .mobile-menu__item span {
    cursor: pointer;
  }
  .mobile-menu__item.active {
    opacity: 1;
    border-bottom: 3px solid #228dff;
  }
  #menu_panel .settings {
    display: none;
  }
  #menu_panel .vpn {
    display: none;
  }
  #menu_panel .icon {
    font-size: 24px;
  }
  #menu_panel #search_cont {
    margin-right: 10px;
  }
  #menu_panel #search_cont #search_input {
    font-size: 16px;
  }
  #header {
    height: 90px;
    box-shadow: none;
  }
  #logo {
    margin-left: -195px;
    height: 55px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  #toolbar {
    position: relative;
  }
  #toolbar:after {
    content: "";
    position: relative;
    display: block;
    clear: both;
  }
  .nav.sections .btn {
    font-size: 16px;
    margin-right: 15px;
  }
  .nav.sections .btn .icon2 {
    font-size: 40px;
    height: 48px;
  }
  #menu_panel {
    top: 30px;
    margin-top: -10px;
  }
}
@media screen and (max-width: 400px) {
  #logo {
    position: relative;
    display: inline-block;
    margin-left: 15px;
    left: 0;
    vertical-align: top;
    margin-top: 0;
    top: 0;
    width: 135px;
  }
  #menu_panel {
    top: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .mobile-only {
    display: none;
  }
  #header .mobile-only {
    display: none;
  }
  #toolbar {
    display: initial;
  }
}
